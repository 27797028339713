import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Stadiums from '../views/Stadiums.vue'
import Fairs from '../views/Fairs.vue'
import Museums from '../views/Museums.vue'
import ServiceDesk from '../views/ServiceDesk.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'ticket2pass',
    component: Home
  },
  {
    path: '/estadios',
    name: 'Arenas y Estadios',
    component: Stadiums
  },
  {
    path: '/ferias',
    name: 'Ferias y Convenciones',
    component: Fairs
  },
  {
    path: '/museos',
    name: 'Museos y Atracciones Turísticas',
    component: Museums
  },
  {
    path: '/mesa-de-servicios',
    name: 'Mesa de Servicios',
    component: ServiceDesk
  },
  {
    path: '/aviso-de-privacidad',
    name: 'Aviso de Privacidad',
    component: () => import('../views/PrivacyPolicy.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.document.title =
    to.fullPath === '/' ? 'ticket2pass' : 't2p - ' + to.name
  next()
})

export default router
